import React, { useState } from 'react';

import ECGSketch from '../home/monitors/ECGSketch.js';
import ECGHeader from './ECGHeader.js';
import InteractiveECGStrip from './InteractiveECGStrip.js';

const PeakDetection = () => {
  //   const [ecgRuntimeSettings, setECGRuntimeSettings] = useState({
  //     refreshRate: 25,
  //     heartRate: 22,
  //   });

  return (
    <div className="flex col grow" style={{ flex: 1 }}>
      <InteractiveECGStrip />
      {/* <ECGHeader
        ecgRuntimeSettings={ecgRuntimeSettings}
        setECGRuntimeSettings={setECGRuntimeSettings}
      />
      <InteractiveECGStrip {...ecgRuntimeSettings} /> */}
      <div className="flex col" style={{ flex: 1 }}>
        {/*  */}
      </div>
    </div>
  );
};

export default PeakDetection;
