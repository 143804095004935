/* eslint-disable react/no-array-index-key */
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { Radio } from 'semantic-ui-react';

import constants from '../../util/constants.js';
import ExternalLink from '../ui/ExternalLink.js';
import LabeledInput from '../ui/LabeledInput.js';
import OptionsChart from './OptionsChart.js';
import {
  OPTION_MODES,
  OPTION_TYPES,
  americanCallOption,
  americanPutOption,
} from './binomialFunctions.js';

import './style/OptionsPricer.scss';

const FIELDS = [
  {
    text: 'stockPrice',
    display: 'Stock Price ($)',
    placeholder: 'dollars',
  },
  {
    text: 'strikePrice',
    display: 'Strike Price ($)',
    placeholder: 'dollars',
  },
  {
    text: 'volatility',
    display: 'Implied Volatility',
    placeholder: 'percentage',
    maxValue: 1.0,
  },
  {
    text: 'riskFreeInterestRate',
    display: 'Interest Rate',
  },
  {
    text: 'timeToExpirationMonths',
    display: 'Life Of Option (months)',
    placeholder: 'number of months',
  },
  {
    text: 'numberOfTimePoints',
    placeholder: 'number of time points',
    maxValue: 100,
  },
];

const OptionsPricer = () => {
  const [treeSeedValues, setValues] = useState({});

  useEffect(() => {
    /** seeding random values */
    setValues({
      stockPrice: 50,
      strikePrice: 52,
      volatility: 0.3,
      riskFreeInterestRate: 0.05,
      timeToExpirationMonths: 2,
      numberOfTimePoints: 5,
      optionType: OPTION_TYPES.CALL,
      optionMode: OPTION_MODES.AMERICAN,
    });
  }, []);

  const S = treeSeedValues.stockPrice;
  const K = treeSeedValues.strikePrice;
  const Tmonths = treeSeedValues.timeToExpirationMonths;
  const r = treeSeedValues.riskFreeInterestRate;
  const sigma = treeSeedValues.volatility;
  const n = treeSeedValues.numberOfTimePoints;

  const optionFunc =
    treeSeedValues.optionType === OPTION_TYPES.CALL ? americanCallOption : americanPutOption;
  const callOptionCalculation = optionFunc(S, K, Tmonths, r, sigma, n);

  return (
    <div className="flex col options-pricer-container">
      <p style={{ fontSize: '1.33em', marginBottom: '1em', fontWeight: 500 }}>
        <ExternalLink
          destination={constants.LINKS.BLACK_SHOLES_LINK}
          text="Binomial option pricing model"
        />
      </p>
      <div className="options-content">
        <div style={{ flex: 2 }}>
          <OptionsChart
            callOptionCalculation={callOptionCalculation}
            treeSeedValues={treeSeedValues}
          />
        </div>
        <div className="flex col" style={{ flex: 1, padding: '1em' }}>
          <div className="flex col">
            <div className="flex" style={{ marginBottom: '0em' }}>
              <span style={{ margin: 'auto 0.5em auto 0' }}>Type:</span>
              <div className="flex">
                <Radio
                  label="Call"
                  checked={treeSeedValues.optionType === OPTION_TYPES.CALL}
                  onChange={() => setValues({ ...treeSeedValues, optionType: OPTION_TYPES.CALL })}
                  className="action-area rounded"
                  style={{ padding: '0.5em' }}
                />
                <Radio
                  label="Put"
                  checked={treeSeedValues.optionType === OPTION_TYPES.PUT}
                  onChange={() => setValues({ ...treeSeedValues, optionType: OPTION_TYPES.PUT })}
                  className="action-area rounded"
                  style={{ padding: '0.5em' }}
                />
              </div>
            </div>
            <div className="flex" style={{ marginBottom: '1em' }}>
              <span style={{ margin: 'auto 0.5em auto 0' }}>Mode:</span>
              <div className="flex">
                <Radio
                  label="American"
                  checked={treeSeedValues.optionMode === OPTION_MODES.AMERICAN}
                  className="action-area rounded"
                  style={{ padding: '0.5em', color: 'red' }}
                />
                <Radio
                  label="European"
                  disabled
                  checked={treeSeedValues.optionMode === OPTION_MODES.EUROPEAN}
                  className="action-area rounded"
                  style={{ padding: '0.5em', color: 'red' }}
                />
              </div>
            </div>
            <div className="responsive-layout-narrow">
              {_.chunk(FIELDS, 3).map((chunk, idx) => (
                <div className="flex col" key={`${idx}`} style={{ margin: '0.5em' }}>
                  {chunk.map((f) => {
                    return (
                      <LabeledInput
                        key={f.text}
                        inputLabel={f.display || _.startCase(f.text)}
                        placeholder={f.placeholder}
                        value={treeSeedValues[f.text] || ''}
                        onChange={(e, { value }) => {
                          let newValue = value || 0;
                          if (f.maxValue && newValue > f.maxValue) {
                            newValue = f.maxValue;
                          }

                          setValues({
                            ...treeSeedValues,
                            [f.text]: newValue,
                          });
                        }}
                      />
                    );
                  })}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OptionsPricer;
