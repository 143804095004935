import React from 'react';
import ReactDOM from 'react-dom/client';
import { HashRouter } from 'react-router-dom';

import RootRouter from './src/RootRouter.js';

import './index.scss';

self.$RefreshReg$ = () => {};
self.$RefreshSig$ = () => () => {};

window.$RefreshReg$ = () => {};
window.$RefreshSig$ = () => () => {};

const rootContainer = document.getElementById('root');

const rootNode = ReactDOM.createRoot(rootContainer);

rootNode.render(
  <HashRouter>
    <RootRouter />
  </HashRouter>,
);
