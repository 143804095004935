// /**
//  *  Draw a rectangle of size (canvas.width - 1, canvas.height - 1)  with dark background
//  * and monitor's color.
//  */
// const MONITOR_BACKGROUND_COLOR = '#201D1D'; // blackish

// export const drawMonitorBackground = ({
//   p5,
//   currentWidth,
//   currentHeight,
//   rgbColorArr,
//   guideLines,
// }) => {
//   p5.push();

//   p5.fill(MONITOR_BACKGROUND_COLOR);

//   p5.stroke(...rgbColorArr, 1);

//   p5.rect(0, 0, currentWidth, currentHeight);
//   // p5.clear(0, 0, currentWidth, currentHeight);

//   if (guideLines) {
//     // console.log(guideLines);
//     guideLines.forEach((yCoordinate) => {
//       p5.line(0, yCoordinate, currentWidth, yCoordinate);
//     });
//   }

//   p5.pop();
// };

const MONITOR_BACKGROUND_COLOR = '#201D1D'; // blackish

export const drawMonitorBackground = ({
  p5,
  currentWidth,
  currentHeight,
  rgbColorArr,
  guideLines,
}) => {
  p5.push();
  p5.fill(MONITOR_BACKGROUND_COLOR);
  p5.stroke(...rgbColorArr, 1);
  p5.rect(0, 0, currentWidth, currentHeight);
  if (guideLines) {
    guideLines.forEach((yCoordinate) => {
      p5.line(0, yCoordinate, currentWidth, yCoordinate);
    });
  }
  p5.pop();
};
