class Monitor {
  constructor({ graphZero, values, maxValuesHistory, monitorColorRGBArr }) {
    this.graphZero = graphZero;
    this.values = values;
    this.maxValuesHistory = maxValuesHistory;
    this.maximumX = maxValuesHistory;
    this.monitorColorRGBArr = monitorColorRGBArr;
    this.minY = null;
    this.maxY = null;
  }

  addValue(value) {
    if (this.values.length >= this.maxValuesHistory) this.values.splice(0, 1);
    if (value.x === undefined) {
      value.x = (this.values[this.values.length - 1].x + 1) % this.maximumX;
    }
    this.values.push(value);
    if (this.minY === null || Math.abs(value.y) >= this.minY) {
      this.minY = Math.abs(value.y) + this.graphZero.y;
    }
    if (this.maxY === null || value.y < this.maxY) {
      this.maxY = value.y;
    }
  }

  plotValues(p5) {
    p5.push();
    p5.strokeWeight(1);
    for (let i = 1; i < this.values.length; i += 1) {
      if (this.values[i - 1].x > this.values[i].x) continue;
      const alpha = i / this.values.length;
      const effectiveColorArr = this.values[i].color || this.monitorColorRGBArr;
      p5.stroke(...effectiveColorArr, alpha);
      p5.line(
        this.graphZero.x + this.values[i - 1].x,
        this.graphZero.y + this.values[i - 1].y,
        this.graphZero.x + this.values[i].x,
        this.graphZero.y + this.values[i].y,
      );
      if (i + 5 > this.values.length) {
        p5.circle(
          this.graphZero.x + this.values[i].x,
          this.graphZero.y + this.values[i].y,
          this.values.length / i,
        );
      }
    }
    p5.pop();
  }

  updateMonitorValue(updateValue, htmlId) {
    if (document.getElementById(htmlId)) {
      document.getElementById(htmlId).innerHTML = updateValue;
    }
  }
}

export default Monitor;
