/* eslint-disable react/no-deprecated */
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { createRef } from 'react';
import { Link } from 'react-router-dom';
import { Icon } from 'semantic-ui-react';

import './style/SideDrawer.scss';

const MenuItem = ({ text, sub, link, iconName }) => (
  <Link className="flex menu-item-container" to={link}>
    <Icon name={iconName} style={{ margin: '0 1rem 0 1rem' }} />
    <span style={{ margin: 'auto auto auto 0em' }}>{text}</span>
    {sub && <span>{sub}</span>}
  </Link>
);

MenuItem.propTypes = {
  text: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  iconName: PropTypes.string.isRequired,
  sub: PropTypes.string,
};

MenuItem.defaultProps = {
  sub: '',
};

const CategoryTitle = ({ title }) => <div className="category-title">{title}</div>;

CategoryTitle.propTypes = {
  title: PropTypes.string.isRequired,
};

class SideDrawer extends React.Component {
  constructor(props) {
    super(props);

    this.monitorClick = this.monitorClick.bind(this);
    this.drawer = createRef();
  }

  componentWillMount() {
    document.addEventListener('mousedown', this.monitorClick, false);
  }

  componentWillUnmount() {
    document.addEventListener('mousedown', this.monitorClick, false);
  }

  monitorClick(e) {
    const { setBarOpen } = this.props;

    if (this.drawer && !this.drawer.contains(e.target)) {
      setBarOpen(false);
    }
  }

  render() {
    const { open, setBarOpen } = this.props;

    return (
      <div className={classNames('container', { open })} ref={(node) => (this.drawer = node)}>
        <div className="flex col content" onClick={() => setBarOpen(false)}>
          <MenuItem text="Home" link="/" iconName="home" />
          <MenuItem text="About" link="/about" iconName="user outline" />
          <CategoryTitle title="Projects" />
          {/* <MenuItem
            text="ECG Peak Detection"
            link="/projects/ecg_peak_detection"
            iconName="heartbeat"
          /> */}
          <MenuItem
            text="Options Pricer"
            link="/projects/options_pricer"
            iconName="chart bar outline"
          />
          {/* <MenuItem text="Whiteboard" link="/projects/whiteboard" iconName="clipboard outline" /> */}
          {/* <CategoryTitle title="Professional" />
          <MenuItem
            text="Herophilus"
            sub="Orchestra"
            link="/projects/options_pricer"
            iconName="lab"
          />
          <MenuItem text="NCSA" link="/projects/options_pricer" iconName="food" sub="HarvestHub" />
          <MenuItem
            text="Beckman Institute"
            sub="Sutton Lab"
            link="/projects/options_pricer"
            iconName="dna"
          /> */}
        </div>
      </div>
    );
  }
}

SideDrawer.propTypes = {
  open: PropTypes.bool.isRequired,
  setBarOpen: PropTypes.func.isRequired,
};

export default SideDrawer;
