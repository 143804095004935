import React, { useState } from 'react';
import { Icon } from 'semantic-ui-react';

import constants from '../../util/constants.js';
import SideDrawer from './SideDrawer.js';

import './style/NavBar.scss';

const {
  LINKS: { GITHUB_LINK, LINKEDIN_LINK },
} = constants;

const NavBar = () => {
  const [sideBarOpen, setBarOpen] = useState(false);

  return (
    <div className="flex nav-bar side-drawer">
      <SideDrawer open={sideBarOpen} setBarOpen={setBarOpen} />
      <Icon
        name="sidebar"
        onClick={() => setBarOpen(true)}
        size="large"
        circular
        className="icon-button"
        style={{ margin: 'auto auto auto 0', fontSize: '1.33em' }}
      />
      <div
        className="flex"
        style={{
          margin: 'auto 0 auto auto',
          fontSize: '1.5rem',
        }}
      >
        <a href={GITHUB_LINK}>
          <Icon name="github" style={{ cursor: 'pointer', margin: 'auto 0.5em auto 0' }} />
        </a>
        <a href={LINKEDIN_LINK}>
          <Icon name="linkedin" style={{ cursor: 'pointer' }} />
        </a>
      </div>
    </div>
  );
};

export default NavBar;
