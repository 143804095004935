import MeImage from '../../../assets/images/profile.jpg';
import React from 'react';
import { Link } from 'react-router-dom';

import constants from '../../util/constants.js';
import ExternalLink from '../ui/ExternalLink.js';
import CircularImage from './CircularImage.js';

const AboutMe = () => (
  <div className="responsive-layout grow">
    <div
      className="flex col"
      style={{ flex: 1, alignItems: 'center', justifyContent: 'center', padding: '1em' }}
    >
      <CircularImage src={MeImage} height={300} width={300} />
      <p style={{ marginBottom: '1em', fontSize: '1.25em', textAlign: 'justify' }}>
        I am a third year medical student at{' '}
        <ExternalLink
          text="Carle Illinois College of Medicine"
          destination="https://medicine.illinois.edu/"
        />{' '}
        (University of Illinois Urbana-Champaign) planning on going into radiology with a focus on
        radiomics. Prior to medical school, I spent over 5 years at{' '}
        <ExternalLink text="Herophilus" destination="https://herophilus.com" />, where I was one of
        the first employees and lead engineer on the{' '}
        <ExternalLink
          text="Orchestra"
          destination="https://medium.com/@dflanzer/herophilus-orchestra-the-best-drug-discovery-operating-system-youve-never-heard-of-9f28ff7fe852"
        />{' '}
        drug discovery/development platform (
        <ExternalLink
          text="acquired by Genentech/Roche in 2023"
          destination=" https://www.businesswire.com/news/home/20230905121651/en/Herophilus-Announces-Strategic-Transaction-with-Genentech-for-Next-Generation-Drug-Discovery-Platform"
        />
        ). Since starting medical school, I have developed an{' '}
        <ExternalLink
          text="award-winning mobile application"
          destination="https://www.ncsa.illinois.edu/harvesthub-app-tackles-supply-chain-food-insecurity-issues/"
        />{' '}
        to combat food waste with the{' '}
        <ExternalLink destination="https://www.ncsa.illinois.edu/" text="NCSA" /> and Google, helped{' '}
        <ExternalLink text="Retro Biosciences" destination="https://retro.bio/" /> engineer their
        foundational lab automation software, and developed novel statistical analysis methods for{' '}
        <ExternalLink
          destination="https://medicine.illinois.edu/news/cruncing-the-data-mri-powered-research-maps-the-brains-role-in-swallowing"
          text="simultaneous fMRI/MRI acquisition"
        />
        .
      </p>
      <p style={{ fontSize: '1.25em', textAlign: 'justify' }}>
        I am passionate about the advances web-native technology can bring to biological research,
        medicine, and medical education. As a physician, I plan to research how computer vision can
        be used to improve and standardize the extraction of latent information from medical images.
        Outside of medical school, work/research, and{' '}
        <span style={{ textDecoration: 'line-through' }}>wrangling</span> raising a toddler, I enjoy
        following the Bay Area biotechnology startup scene, lifting weights,{' '}
        <Link className="external-link" to="/projects/options_pricer">
          investing
        </Link>
        , working my way through the{' '}
        <ExternalLink
          text="Great Books"
          destination="https://www.sjc.edu/academic-programs/undergraduate/great-books-reading-list"
        />
        , and working on the{' '}
        <ExternalLink
          text="web app that helps me manage my life"
          destination="https://github.com/TonyBosshardt/whiteboard"
        />
        .
      </p>
    </div>
    <div className="flex" style={{ flex: 1, padding: '1em' }}>
      <object
        data={constants.LINKS.RESUME_LINK}
        type="application/pdf"
        className="rounded"
        id="object-resume-pdf"
        style={{ flex: 1, minHeight: '75vh' }}
      >
        {null}
      </object>
    </div>
  </div>
);

export default AboutMe;
