import React from 'react';
import Sketch from 'react-p5';

import Monitor from './Monitor.js';
import { drawMonitorBackground } from './utils.js';

let drawIdx = 0;

const MONITOR_HEIGHT_PX = 150;
const WIDTH_OFFSET = 300;
const RGB_COLOR = [255, 25, 25]; // Red color for the MABP waveform
const HTML_ID_TRACKER = 'mabp-value';
const MARGIN = 30; // Margin from the top and bottom

const monitor = new Monitor({
  graphZero: { x: 0, y: 50 },
  values: [{ x: 0, y: 0 }],
  maxValuesHistory: window.innerWidth - WIDTH_OFFSET,
  monitorColorRGBArr: RGB_COLOR,
});

class MABP {
  constructor({ cycleDuration, amplitude }) {
    this.cycleDuration = cycleDuration;
    this.amplitude = amplitude;
    this.nextBeat = 1000;
    this.nextBreathIn = 1000;
  }

  setAmplitude(voltage, color) {
    monitor.addValue({ y: voltage, color });
  }

  updateMABP(p5) {
    this.nextBeat = p5.abs(p5.ceil(p5.randomGaussian((900 - 400) / 10, 3)));
    if (this.nextBeat < 18) this.nextBeat = 18;
    monitor.updateMonitorValue(
      // p5.round(60 / (this.nextBeat / 1000)), // Dummy BPM value for display
      //   p5.round(60 / (this.nextBeat / 1000)),
      Math.floor(Math.random() * (99 - 70 + 1)) + 70,
      //   75,
      HTML_ID_TRACKER,
    );
  }

  updateTimeToNextBeat(p5) {
    if (this.nextBreathIn-- === 0) {
      this.updateMABP(p5);
      this.nextBreathIn = this.nextBeat;
    }
  }

  generateWaveform(time) {
    let nextY;
    const normalizedTime = (time % this.cycleDuration) / this.cycleDuration;

    if (normalizedTime < 0.2) {
      // Smooth gradual rise using a sine function
      nextY = MARGIN + this.amplitude * Math.sin(((normalizedTime / 0.2) * Math.PI) / 2);
    } else {
      // Smooth gradual fall using a cosine function
      nextY = MARGIN + this.amplitude * Math.cos(((normalizedTime - 0.2) / 0.8) * Math.PI);
    }

    this.setAmplitude(nextY);
  }

  update(p5, time) {
    this.updateTimeToNextBeat(p5);
    this.generateWaveform(time % this.cycleDuration);
  }
}

const mabp = new MABP({
  cycleDuration: 100, // Adjust the overall cycle duration for better frequency
  amplitude: (MONITOR_HEIGHT_PX - 2 * MARGIN) / 2,
});

class MABPSketch extends React.Component {
  constructor(props) {
    super(props);
    this.state = { currentWidth: window.innerWidth - WIDTH_OFFSET, p5: null };
    this.handleResizeCanvas = this.handleResizeCanvas.bind(this);
    this.setup = this.setup.bind(this);
    this.draw = this.draw.bind(this);
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleResizeCanvas);
  }

  handleResizeCanvas() {
    const { p5 } = this.state;
    this.setState({ currentWidth: window.innerWidth - WIDTH_OFFSET });
    if (p5) {
      p5.resizeCanvas(window.innerWidth - WIDTH_OFFSET, MONITOR_HEIGHT_PX);
    }
  }

  setup(p5, canvasParentRef) {
    const { currentWidth } = this.state;
    p5.createCanvas(currentWidth, MONITOR_HEIGHT_PX).parent(canvasParentRef);
    p5.colorMode(p5.RGB, 255, 255, 255, 1);
    p5.angleMode(p5.DEGREES);
    this.setState({ p5 });
  }

  draw(p5) {
    const { currentWidth } = this.state;
    drawIdx += 1;
    drawMonitorBackground({
      p5,
      currentWidth,
      currentHeight: MONITOR_HEIGHT_PX,
      rgbColorArr: RGB_COLOR,
    });
    mabp.update(p5, drawIdx % mabp.cycleDuration);
    monitor.plotValues(p5);
  }

  render() {
    const { currentWidth } = this.state;
    return (
      <div className="flex mabp">
        <div style={{ position: 'relative' }}>
          <div style={{ width: currentWidth }}>
            <Sketch setup={this.setup} draw={this.draw} />
          </div>
          <span
            style={{
              position: 'absolute',
              top: '0.5em',
              left: '0.5em',
              fontSize: '1.15em',
              color: `rgb(${RGB_COLOR[0]}, ${RGB_COLOR[1]}, ${RGB_COLOR[2]})`,
            }}
          >
            MAP
          </span>
        </div>
        <div className="mabp flex" style={{ flex: 1, position: 'relative', marginLeft: '1em' }}>
          <span id={HTML_ID_TRACKER} style={{ fontSize: '6em', margin: 'auto' }}>
            75
          </span>
          <span style={{ position: 'absolute', top: '0.5em', left: '1em' }}>mmHg</span>
        </div>
      </div>
    );
  }
}

export default MABPSketch;
