import _ from 'lodash';
import React from 'react';
import Sketch from 'react-p5';

import Monitor from './Monitor.js';
import { drawMonitorBackground } from './utils.js';

let drawIdx = 0;

const MONITOR_HEIGHT_PX = 150;
const WIDTH_OFFSET = 300;
const RGB_COLOR = [73, 176, 203]; // cyan
const HTML_ID_TRACKER = 'pleth-value';

const monitor = new Monitor({
  graphZero: { x: 0, y: MONITOR_HEIGHT_PX - 20 },
  values: [{ x: 0, y: 0 }],
  maxValuesHistory: window.innerWidth - WIDTH_OFFSET,
  monitorColorRGBArr: RGB_COLOR,
});

class Lungs {
  constructor({ adDuration, vdDuration, vrDuration, inspirationDuration, expirationDuration }) {
    this.adDuration = adDuration;
    this.vdDuration = vdDuration;
    this.vrDuration = vrDuration;
    this.inspirationDuration = inspirationDuration;
    this.expirationDuration = expirationDuration;
    this.beatDuration = adDuration + vdDuration + vrDuration;
    this.nextBeat = 1000;
    this.nextBreathIn = 1000;
    this.bpm = [];
    this.voltage = 0;
  }

  setAmplitude(voltage, color) {
    this.voltage = voltage;
    monitor.addValue({ y: this.voltage, color });
  }

  updatePleth(p5) {
    this.bpm.push(3600 / this.nextBeat);
    if (this.bpm.length > 5) this.bpm.splice(0, 1);
    monitor.updateMonitorValue(
      p5.round(this.bpm.reduce((p, c) => p + c, 0) / this.bpm.length),
      HTML_ID_TRACKER,
    );
  }

  updateTimeToNextBreath(p5) {
    if (this.nextBreathIn-- === 0) {
      this.nextBeat = p5.abs(p5.ceil(p5.randomGaussian((900 - 400) / 10, 3)));
      if (this.nextBeat < 18) this.nextBeat = 18;
      this.updatePleth(p5);
      this.nextBreathIn = this.nextBeat;
    }
  }

  inspiration(time) {
    const { y: previousY } = _.last(monitor.values);
    let nextY;
    const inflection = this.inspirationDuration / 2;
    if (time <= inflection) {
      const timeToInflection = inflection - time;
      nextY = previousY - 0.3853;
      if (timeToInflection < 10) {
        nextY -= 0.1 * (10 - timeToInflection);
      }
    } else {
      nextY = previousY - 3.75;
      const timeToExhale = this.inspirationDuration - time;
      if (timeToExhale < 10) {
        nextY = previousY - 0.2 * timeToExhale;
      }
    }
    this.setAmplitude(nextY);
  }

  expiration(time) {
    const { y: previousY } = _.last(monitor.values);
    let nextY;
    const color = RGB_COLOR;
    if (time < 10) {
      nextY = previousY + 0.2 * time;
    } else if (time <= this.expirationDuration / 3) {
      nextY = previousY + 3.75;
    } else if (time < (2 * this.expirationDuration) / 3) {
      const min = this.expirationDuration / 3;
      const idxIn = time - min;
      if (idxIn < 5) {
        nextY = previousY + 3.75;
      } else if (idxIn < 7) {
        nextY = previousY + 0.2 * (10 - idxIn);
      } else {
        nextY = previousY + 0.01;
      }
    } else {
      const min = (2 * this.expirationDuration) / 3;
      const idxIn = time - min;
      const idxLeft = this.expirationDuration - time;
      if (idxIn < 5) {
        nextY = previousY + 0.1 * idxIn;
      } else if (idxLeft < 5) {
        nextY = previousY + 0.2 * (5 - idxLeft);
      } else {
        nextY = previousY + 3.1;
      }
    }
    this.setAmplitude(nextY, color);
  }

  breathe(p5, breathIdx) {
    this.updateTimeToNextBreath(p5);
    if (breathIdx <= this.inspirationDuration) {
      this.inspiration(breathIdx, p5);
    } else {
      this.expiration(breathIdx - this.inspirationDuration, p5);
    }
  }
}

const lungs = new Lungs({
  adDuration: 12,
  vdDuration: 8,
  vrDuration: 12,
  inspirationDuration: 50,
  expirationDuration: 50,
});

const breathDuration = lungs.inspirationDuration + lungs.expirationDuration;

class PlethSketch extends React.Component {
  constructor(props) {
    super(props);
    this.state = { currentWidth: window.innerWidth - WIDTH_OFFSET, p5: null };
    this.handleResizeCanvas = this.handleResizeCanvas.bind(this);
    this.setup = this.setup.bind(this);
    this.draw = this.draw.bind(this);
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleResizeCanvas);
  }

  handleResizeCanvas() {
    const { p5 } = this.state;
    this.setState({ currentWidth: window.innerWidth - WIDTH_OFFSET });
    p5.resizeCanvas(window.innerWidth - WIDTH_OFFSET, MONITOR_HEIGHT_PX);
  }

  setup(p5, canvasParentRef) {
    const { currentWidth } = this.state;
    p5.createCanvas(currentWidth, MONITOR_HEIGHT_PX).parent(canvasParentRef);
    p5.colorMode(p5.RGB, 255, 255, 255, 1);
    p5.angleMode(p5.DEGREES);
    this.setState({ p5 });
  }

  draw(p5) {
    const { currentWidth } = this.state;
    drawIdx += 1;
    drawMonitorBackground({
      p5,
      currentWidth,
      currentHeight: MONITOR_HEIGHT_PX,
      rgbColorArr: RGB_COLOR,
    });
    lungs.breathe(p5, drawIdx % breathDuration);
    monitor.plotValues(p5);
  }

  render() {
    const { currentWidth } = this.state;
    return (
      <div className="flex pleth">
        <div style={{ position: 'relative' }}>
          <div style={{ width: currentWidth }}>
            <Sketch setup={this.setup} draw={this.draw} />
          </div>
          <span
            style={{
              position: 'absolute',
              top: '0.5em',
              left: '0.5em',
              fontSize: '1.15em',
              color: `rgb(${RGB_COLOR[0]}, ${RGB_COLOR[1]}, ${RGB_COLOR[2]})`,
            }}
          >
            PLETH
          </span>
        </div>
        <div className="pleth flex" style={{ flex: 1, position: 'relative', marginLeft: '1em' }}>
          <span id="pleth-value" style={{ fontSize: '6em', margin: 'auto' }}>
            60
          </span>
          <span style={{ position: 'absolute', top: '0.5em', left: '1em' }}>SpO2</span>
        </div>
      </div>
    );
  }
}

export default PlethSketch;
