import React from 'react';
import { Route, Switch } from 'react-router-dom';

import AboutMe from './components/about/AboutMe.js';
import PeakDetection from './components/ecg/PeakDetection.js';
import Home from './components/home/Home.js';
import NavBar from './components/nav/NavBar.js';
import OptionsPricer from './components/optionsPricer/OptionsPricer.js';

const RootContainer = ({ children }) => (
  <div key="root-content" className="flex col root-container">
    <NavBar />
    <div className="content-container">{children}</div>
  </div>
);

const RootRouter = () => (
  <RootContainer>
    <Switch>
      <Route exact path="/" component={Home} />
      <Route exact path="/about" component={AboutMe} />
      <Route exact path="/projects/options_pricer" component={OptionsPricer} />
      <Route exact path="/projects/ecg_peak_detection" component={PeakDetection} />
    </Switch>
  </RootContainer>
);

export default RootRouter;
