import PropTypes from 'prop-types';
import React from 'react';
import { Dropdown } from 'semantic-ui-react';

import './style/LabeledDropdown.scss';

const LabeledDropdown = ({ label, ...dropdownProps }) => (
  <div className="flex col labeled-dropdown">
    <span className="primary-text ">{label}</span>
    <Dropdown search selection {...dropdownProps} />
  </div>
);

LabeledDropdown.propTypes = {
  label: PropTypes.string.isRequired,
};

export default LabeledDropdown;
