/* eslint-disable no-template-curly-in-string */
const BACKGROUND_COLOR_DARK = '#212121';
const FONT_COLOR_DARK = 'rgb(232, 234, 237)';

export const CHART_CONFIG = {
  chart: {
    type: 'scatter',
    backgroundColor: BACKGROUND_COLOR_DARK,
    style: {
      fontFamily: 'monospace',
      color: FONT_COLOR_DARK,
      borderRadius: '0.28em',
    },
  },
  title: {
    text: null,
  },
  yAxis: {
    gridLineColor: 'rgb(102, 102, 102)',
    title: {
      text: 'Stock Price ($)',
      style: {
        fontSize: '1.25em',
      },
    },
    labels: {
      style: {
        color: FONT_COLOR_DARK,
        fontSize: '1.25em',
      },
    },
  },
  xAxis: {
    allowDecimals: false,
    title: {
      text: 'Time Step',
      style: {
        fontSize: '1.25em',
      },
    },
    labels: {
      style: {
        color: FONT_COLOR_DARK,
        fontSize: '1.25em',
      },
    },
  },
  tooltip: {
    pointFormat: 'Stock Price: ${point.stockPrice:.2f}<br/>Option Value: ${point.optionPrice:.2f}',
  },
  legend: {
    enabled: false,
  },
  plotOptions: {
    series: {
      label: {
        connectorAllowed: false,
      },
    },
  },
  credits: {
    enabled: false,
  },

  series: [],
};
