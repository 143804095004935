export function americanPutOption(S, K, T_months, r, sigma, n) {
  const T = T_months / 12;

  const dt = T / n;
  const u = Math.exp(sigma * Math.sqrt(dt));
  const d = 1 / u;
  const p = (Math.exp(r * dt) - d) / (u - d);

  // Construct the binomial tree
  const tree = [];
  for (let i = 0; i <= n; i += 1) {
    const row = [];
    for (let j = 0; j <= i; j += 1) {
      row.push({ stockPrice: S * Math.pow(u, j) * Math.pow(d, i - j) });
    }
    tree.push(row);
  }

  // Calculate option prices at each node
  for (let i = n; i >= 0; i -= 1) {
    for (let j = 0; j <= i; j += 1) {
      const optionValue = Math.max(0, K - tree[i][j].stockPrice);
      tree[i][j].optionPrice = optionValue;
    }
  }

  // Backtrack through the tree to calculate option price at t=0
  for (let i = n - 1; i >= 0; i -= 1) {
    for (let j = 0; j <= i; j += 1) {
      const discountedOptionValue =
        Math.exp(-r * dt) *
        (p * tree[i + 1][j + 1].optionPrice + (1 - p) * tree[i + 1][j].optionPrice);
      const intrinsicValue = Math.max(0, K - tree[i][j].stockPrice);
      tree[i][j].optionPrice = Math.max(discountedOptionValue, intrinsicValue);
    }
  }
  // return tree;
  return tree;
}

export const americanCallOption = (S, K, T_months, r, sigma, n) => {
  const T = T_months / 12;

  const dt = T / n;
  const u = Math.exp(sigma * Math.sqrt(dt));
  const d = 1 / u;
  const p = (Math.exp(r * dt) - d) / (u - d);

  // Construct the binomial tree
  const tree = [];
  for (let i = 0; i <= n; i += 1) {
    const row = [];
    for (let j = 0; j <= i; j += 1) {
      row.push({ stockPrice: S * Math.pow(u, j) * Math.pow(d, i - j) });
    }
    tree.push(row);
  }

  // Calculate option prices at each node
  for (let i = n; i >= 0; i -= 1) {
    for (let j = 0; j <= i; j += 1) {
      const optionValue = Math.max(0, tree[i][j].stockPrice - K);
      tree[i][j].optionPrice = optionValue;
    }
  }

  // Backtrack through the tree to calculate option price at t=0
  for (let i = n - 1; i >= 0; i -= 1) {
    for (let j = 0; j <= i; j += 1) {
      const discountedOptionValue =
        Math.exp(-r * dt) *
        (p * tree[i + 1][j + 1].optionPrice + (1 - p) * tree[i + 1][j].optionPrice);
      const intrinsicValue = Math.max(0, tree[i][j].stockPrice - K);
      tree[i][j].optionPrice = Math.max(discountedOptionValue, intrinsicValue);
    }
  }

  return tree;
};

export const OPTION_TYPES = {
  CALL: 'call',
  PUT: 'put',
};

export const OPTION_MODES = {
  AMERICAN: 'american',
  EUROPEAN: 'european',
};

export function americanOption(S, K, timeToExpiryMonths, r, sigma, n, mode) {
  const T = timeToExpiryMonths / 12;

  const dt = T / n;
  const u = Math.exp(sigma * Math.sqrt(dt));
  const d = 1 / u;
  const p = (Math.exp(r * dt) - d) / (u - d);

  // Construct the binomial tree
  const tree = [];
  for (let i = 0; i <= n; i += 1) {
    const row = [];
    for (let j = 0; j <= i; j += 1) {
      row.push({ stockPrice: S * Math.pow(u, j) * Math.pow(d, i - j) });
    }
    tree.push(row);
  }

  // Calculate option prices at each node
  for (let i = n; i >= 0; i -= 1) {
    for (let j = 0; j <= i; j += 1) {
      const optionValue = Math.max(0, K - tree[i][j].stockPrice);
      tree[i][j].optionPrice = optionValue;
    }
  }

  // Backtrack through the tree to calculate option price at t=0
  for (let i = n - 1; i >= 0; i -= 1) {
    for (let j = 0; j <= i; j += 1) {
      const discountedOptionValue =
        Math.exp(-r * dt) *
        (p * tree[i + 1][j + 1].optionPrice + (1 - p) * tree[i + 1][j].optionPrice);
      const intrinsicValue = Math.max(0, tree[i][j].stockPrice - K);
      //   : Math.max(0, K - tree[i][j].stockPrice);
      tree[i][j].optionPrice = Math.max(discountedOptionValue, intrinsicValue);
    }
  }

  return tree;
}
