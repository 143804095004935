import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import ReactHighcharts from 'react-highcharts';

import { CHART_CONFIG } from './BlackSholesChartHelpers.js';

const OptionsChart = ({ callOptionCalculation, treeSeedValues }) => {
  const chartConfig = CHART_CONFIG;

  chartConfig.series = callOptionCalculation.map((timeStepPoints, idx) => {
    return {
      data: timeStepPoints.map(({ stockPrice, optionPrice }) => ({
        x: idx,
        y: stockPrice,
        stockPrice,
        optionPrice,
        color: stockPrice > treeSeedValues.stockPrice ? '#48b0ff' : 'orange',
      })),
      name: `Time Point: ${idx}`,
      marker: {
        symbol: 'circle',
        radius: 4,
      },
    };
  });

  return <ReactHighcharts config={chartConfig} />;
};

OptionsChart.propTypes = {
  callOptionCalculation: PropTypes.object.isRequired,
};

export default OptionsChart;
