import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useRef, useState } from 'react';
import { Input } from 'semantic-ui-react';

import './style/LabeledInput.scss';

const LabeledInput = ({ inputLabel, value, onChange, errorText, ...inputProps }) => {
  const [isFocused, setIsFocused] = useState(false);

  const inputRef = useRef('inputRef');

  return (
    <div className="flex col styled-input-container">
      <span
        className={classNames('input-label', { focused: isFocused || value })}
        onClick={() => {
          const { current } = inputRef;

          if (current) {
            current.focus();
          }
        }}
      >
        {inputLabel}
      </span>
      <Input
        value={value}
        ref={inputRef}
        onChange={onChange}
        onBlur={() => setIsFocused(false)}
        onFocus={() => setIsFocused(true)}
        {...inputProps}
      >
        <input
          className={classNames('interior-input', {
            'interior-input-focused': isFocused,
            'has-value': value !== undefined && value !== null,
          })}
        />
      </Input>
      <span className={classNames('error-text', 'errored')}>{errorText}</span>
    </div>
  );
};

LabeledInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  inputLabel: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  errorText: PropTypes.string,
};

LabeledInput.defaultProps = {
  inputLabel: '',
  value: '',
  errorText: '',
};

export default LabeledInput;
