// import Slider from 'rc-slider';
import React from 'react';
import { Icon } from 'semantic-ui-react';

import LabeledDropdown from '../ui/LabeledDropdown.js';

const ECGHeader = ({ ecgRuntimeSettings, setECGRuntimeSettings }) => (
  <div className="flex" style={{ fontSize: '0.85em', marginBottom: '1em' }}>
    <LabeledDropdown
      label="Rhythm"
      options={[{ text: 'Normal Sinus', value: 'NORMAL_SINUS' }]}
      value="NORMAL_SINUS"
      style={{ marginRight: '1em' }}
    />
    <div className="flex col" style={{ width: '20em' }}>
      <span className="primary-text">Heart Rate</span>
      <div
        className="flex rounded"
        style={{ flex: 1, padding: '0.5em', border: '1px solid rgba(255, 255,255,0.25)' }}
      >
        <Slider
          style={{ margin: 'auto' }}
          min={0}
          max={0.1}
          step={0.01}
          value={1 / ecgRuntimeSettings.heartRate}
          //   onChange={() => }
        />
      </div>
    </div>
    <div className="flex" style={{ marginLeft: 'auto' }}>
      <Icon
        name="step backward"
        className="icon-button"
        circular
        style={{ margin: 'auto 0 0 auto', fontSize: '1.5em' }}
      />
      <Icon
        name="pause"
        className="icon-button"
        circular
        style={{ margin: 'auto 0 0 auto', fontSize: '1.5em' }}
      />
      <Icon
        name="step forward"
        className="icon-button"
        circular
        style={{ margin: 'auto 0 0 auto', fontSize: '1.5em' }}
      />
    </div>
  </div>
);

export default ECGHeader;
