import PropTypes from 'prop-types';
import React from 'react';

const CircularImage = ({ src, height, width }) => (
  <div
    className="circular-image"
    style={{
      width,
      height,
      overflow: 'hidden',
      borderRadius: '50%',
      margin: '1em',
    }}
  >
    <img
      src={src}
      style={{
        width: '100%',
        height: '100%',
        objectFit: 'cover',
      }}
      alt="profile"
    />
  </div>
);

CircularImage.propTypes = {
  src: PropTypes.string.isRequired,
  height: PropTypes.number.isRequired,
  width: PropTypes.number.isRequired,
};

export default CircularImage;
