import React from 'react';
import Sketch from 'react-p5';

const ECG_HEIGHT_PX = 150;

const WIDTH_OFFSET = 300;

// Keep track of the times draw() has been called
let drawIdx = 0;

const RGB_COLOR = [121, 239, 150];

/**
 *  ECG will receive, process, and draw the health information
 */
class ECG {
  /**
   * @param {Object} graphZero  Coordinates of the {0, 0} value of the graph
   * @param {Object[]} values   Array of {x, y} objects. x plots time, y plots voltage
   * @param {number} maxValuesHistory   Maximum number of values before wiping oldest one
   */
  constructor(graphZero, values, maxValuesHistory) {
    this.graphZero = graphZero;
    this.values = values;
    this.maxValuesHistory = maxValuesHistory;
    this.maximumX = maxValuesHistory;
  }

  /**
   * Add a new voltage value to the values array. If it exceeds the maximum number of
   * values allowed to store, remove the oldest one before.
   * @param {Object} value {x, y} object. x represents time, y represents voltage
   */
  addValue(value) {
    // If no x (time) value is received, assume it is the sucessor of the last value
    // in the values array. If the new x exceeds the maximum allowed, make x = 0
    if (this.values.length >= this.maxValuesHistory) this.values.splice(0, 1);
    if (value.x === undefined) {
      value.x = (this.values[this.values.length - 1].x + 1) % this.maximumX;
    }
    this.values.push(value);
  }

  /**
   * Draw lines joining every voltage value throughout time in the screen
   */
  plotValues(p5) {
    p5.push();

    for (let i = 1; i < this.values.length; i += 1) {
      // If the previous value has a X coordinate higher than the current one,
      // don't draw it, to avoid lines crossing from end to start of the ECG plot area.
      if (this.values[i - 1].x > this.values[i].x) continue;

      // Older values are drawn with a lower alpha
      const alpha = i / this.values.length;

      // Set the color of the drawing
      p5.stroke(...RGB_COLOR, alpha);
      p5.fill(...RGB_COLOR, alpha);

      // Line from previous value to current value
      p5.line(
        this.graphZero.x + this.values[i - 1].x,
        this.graphZero.y + this.values[i - 1].y,
        this.graphZero.x + this.values[i].x,
        this.graphZero.y + this.values[i].y,
      );

      // For the last 5 values, draw a circle with a radius going in function to
      // its index. This to make the leading line thicker
      if (i + 5 > this.values.length) {
        p5.circle(
          this.graphZero.x + this.values[i].x,
          this.graphZero.y + this.values[i].y,
          this.values.length / i,
        );
      }
    }
    p5.pop();
  }

  updateInfo(p5) {
    this.updateDate();
    if (drawIdx % 50 === 0) {
      this.updateBloodPressure(p5);
      this.updateVentilation(p5);
      this.updateTemperature(p5);
      this.updateHemoglobin(p5);
    }
  }

  updateHemoglobin(p5) {
    // document.getElementById('hemoglobin-value').innerHTML = p5.randomGaussian(14, 0.1).toFixed(1);
  }

  updateTemperature(p5) {
    // document.getElementById('temperature-value').innerHTML = p5
    //   .randomGaussian(98.6, 0.1)
    //   .toFixed(1);
  }

  updateVentilation(p5) {
    // document.getElementById('minute-ventilation-value').innerHTML = p5
    //   .randomGaussian(6, 0.5)
    //   .toFixed(2);
  }

  updateBloodPressure(p5) {
    // document.getElementById('pressure-value').innerHTML = `${p5.round(
    //   p5.randomGaussian(130, 1)
    // )}/${p5.round(p5.randomGaussian(90, 1))}`;
  }

  updateDate() {
    if (document.getElementById('date-value')) {
      document.getElementById('date-value').innerHTML = new Date().toLocaleString();
    }
  }

  /**
   * Update the html content of the span containing the bpm info
   * @param {number} bpm
   */
  drawBPM(bpm) {
    if (document.getElementById('hr-bpm-value')) {
      document.getElementById('hr-bpm-value').innerHTML = bpm;
    }
  }
}

// Initialize the ecg
const ecg = new ECG({ x: 0, y: 110 }, [{ x: 0, y: 0 }], window.innerWidth - WIDTH_OFFSET);

/**
 * A Heart object will beat, and generate voltage values according to the time
 * the beat started
 *
 * "Duration" values are really pixels. 1 pixel represents 1/60 of a second.
 */
class Heart {
  /**
   * Creates an instance of Heart
   * @param {number} adDuration Duration in pixels of the atria depolarization
   * @param {number} vdDuration Duration in pixels of the ventricle depolarization
   * @param {number} vrDuration Duration in pixels of the ventricle repolarization
   *
   * @property {number} this.beatDuration Duration in pixels of the whole beat
   * @property {number} this.nextBeat Time between last beat, and next beat
   * @property {number} this.nextBeatIn Time remaining for next beat
   * @property {number[]} this.bpm Time between two particular beats
   * @property {number} this.voltage Current voltage value. No units used.
   */
  constructor(adDuration, vdDuration, vrDuration) {
    this.adDuration = adDuration;
    this.vdDuration = vdDuration;
    this.vrDuration = vrDuration;

    this.beatDuration = adDuration + vdDuration + vrDuration;

    this.nextBeat = 60;
    this.nextBeatIn = 60;
    this.bpm = [];
    this.voltage = 0;
  }

  /**
   * Assign the heart a new voltage value, and report that value to the ECG
   * the heart is connected to.
   * @param {number} voltage
   */
  setVoltage(voltage) {
    this.voltage = voltage;
    ecg.addValue({ y: this.voltage });
  }

  /**
   * Generates the voltage values corresponding to the atria depolarization process.
   * This is the process that generates the first part of the curve of every beat.
   *
   * @param {number} time Time in pixels since the atria depolarization process started
   */
  atriaDepolarization(time, p5) {
    // This process is not close to what reality does, but here it is generated using a
    // sin function where only the positive values remain, making a bump followed by a
    // flat section
    let y = p5.randomGaussian(5, 1) * p5.sin(time * (360 / this.adDuration));

    // To compensate for the y-axis inverted direction, return -y when y is over 0
    y = y > 0 ? -y : 0.2 * (1 - y);

    // Update the voltage to whatever value was calculated
    this.setVoltage(y + p5.noise(time));
  }

  /**
   * Generates the voltage values corresponding to the ventricle depolarization process.
   * This is the process that generates the spiky part of the curve of every beat.
   *
   * @param {number} time Time in pixels since the ventricle depolarization process started
   */
  ventricleDepolarization(time, p5) {
    let y;
    // In the first third, the curve has a spike going down
    if (time <= this.vdDuration / 3) y = (p5.randomGaussian(8, 2) * (this.vdDuration - time)) / 6;
    // In the second third, the curve has a big spike going up
    else if (time < (2 * this.vdDuration) / 3) {
      // Start producing a sound, going from 0 to 0.5 volume in 0.01 seconds
      // osc.amp(0.5, 0.01);
      y = (p5.randomGaussian(70, 2) * p5.abs(1.5 - (this.vdDuration - time))) / 3;
      y = -y;
    }

    // In the last third, the curve has another spike (bigger than the first one) going down
    else {
      y = (p5.randomGaussian(20, 2) * (this.vdDuration - time)) / 3;
      // Stop the sound, going from 0.5 to 0 volume in 0.01 secs
      // osc.amp(0, 0.01);
    }

    // Update the voltage to whatever value was calculated
    this.setVoltage(y);
  }

  /**
   * Generates the voltage values corresponding to the ventricle repolarization process.
   * This is the process that generates the last part of the curve of every beat.
   *
   * @param {number} time Time in pixels since the ventricle repolarization process started
   */
  ventricleRepolarization(time, p5) {
    // This process is not close to what reality does, but here it is generated using a
    // sin function where only the positive values remain, but displaced half a turn to
    // make a flat section followed by a bump
    let y = p5.randomGaussian(8, 2) * p5.sin(180 + time * (360 / this.vrDuration));

    // To compensate for the y-axis inverted direction, return -y when y is over 0
    y = y < 0 ? 0.2 * (1 - y) : -y;

    // Update the voltage to whatever value was calculated
    this.setVoltage(y + p5.noise(time));
  }

  updateBPM(p5) {
    // bpm = 3600 / pixel-distance
    this.bpm.push(3600 / this.nextBeat);

    // To make rapid frequency changes meaningful, get the average bpm using only the
    // last 5 values of time, not all of them. So dispose the oldest one when the list
    // length is over 5.
    if (this.bpm.length > 5) this.bpm.splice(0, 1);
    ecg.drawBPM(p5.round(this.bpm.reduce((p, c) => p + c, 0) / this.bpm.length));
  }

  /**
   * Decrease this.nextBeatIn to simulate the pass of time.
   * If necessary, create a new this.nextBeat value
   */
  updateTimeToNextBeat(p5) {
    // This indicates that the next beat will begin in the next iteration
    if (this.nextBeatIn-- === 0) {
      // Then calculate a new "remaining time" for the next beat.
      // Use the x coordinates of the mouse position to modify the heart frequency
      this.nextBeat = p5.abs(p5.ceil(p5.randomGaussian((900 - 400) / 10, 3)));

      // It the pixel time between beat and beat is less than 18, force it to be
      // 18. This value makes to a bpm of 200.
      if (this.nextBeat < 18) this.nextBeat = 18;

      // Get new bpm values using the last this.nextBeat
      this.updateBPM(p5);

      // Reset the remaining time to the new calculated time
      this.nextBeatIn = this.nextBeat;
    }
  }

  /**
   * Get voltage values for every second of the beat, even at rest (no-beating time
   * after the ventricle repolarization finished, and before the next atria depolarization)
   * @param {*} time Time in pixels after the atria depolarization started
   */
  beat(time, p5) {
    // Update the time left for the start of the next beat
    this.updateTimeToNextBeat(p5);

    // If according to time, beat is in the atria depolarization process, call that function
    if (time <= this.adDuration) {
      this.atriaDepolarization(time, p5);
      return;
    }

    // If according to time, beat is in the ventricle depolarization process, call that function
    // Update the time so the value sent is relative to the start of the ventricle
    // depolarization process
    time -= this.adDuration;
    if (time <= this.vdDuration) {
      this.ventricleDepolarization(time, p5);
      return;
    }

    // If according to time, beat is in the ventricle repolarization process, call that function
    // Update the time so the value sent is relative to the start of the ventricle
    // repolarization process
    time -= this.vdDuration;
    if (time <= this.vrDuration) {
      this.ventricleRepolarization(time, p5);
      return;
    }

    // If function reached this point, it's not in any of the beat processes, and it's resting.
    // Add a noisy voltage value
    this.setVoltage(0 + p5.noise(drawIdx * 0.5) * 5);
  }
}

// Initialize a heart
const heart = new Heart(12, 8, 12);

/**
 *  Draw a rectangle of size (canvas.width - 1, canvas.height - 1)  with dark background
 * and a brilliant green border.
 *
 * The -1 is to allow the border to be seen in the final page.
 */
function drawECGScreenBackground(p5, currentWidth) {
  p5.push();

  p5.fill('#201D1D');

  p5.stroke(121, 239, 150, 1);

  p5.rect(0, 0, currentWidth, ECG_HEIGHT_PX);

  p5.pop();
}

class ECGSketch extends React.Component {
  constructor(props) {
    super(props);

    this.state = { currentWidth: window.innerWidth - WIDTH_OFFSET, canvasHandle: null, p5: null };

    this.handleResizeCanvas = this.handleResizeCanvas.bind(this);

    this.setup = this.setup.bind(this);
    this.draw = this.draw.bind(this);
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleResizeCanvas);
  }

  handleResizeCanvas() {
    const { p5 } = this.state;

    this.setState({ currentWidth: window.innerWidth - WIDTH_OFFSET });

    p5.resizeCanvas(window.innerWidth - WIDTH_OFFSET, ECG_HEIGHT_PX);
  }

  setup(p5, canvasParentRef) {
    const { currentWidth } = this.state;

    p5.createCanvas(currentWidth, ECG_HEIGHT_PX).parent(canvasParentRef);

    // Set the color mode to allow calling RGBA without converting to string
    p5.colorMode(p5.RGB, 255, 255, 255, 1);

    // Work with degrees instead of Radians (sin function used inside Heart Class)
    p5.angleMode(p5.DEGREES);

    this.setState({ p5 });
  }

  draw(p5) {
    const { currentWidth } = this.state;

    // Keep track of the number of times draw has been called
    drawIdx += 1;

    // Hide previous ECG line by drawing a background
    drawECGScreenBackground(p5, currentWidth);

    // Get the new voltage values for the ECG from the heart
    heart.beat(heart.nextBeat - heart.nextBeatIn, p5);

    // Draw the line of voltage values over time in the ECG screen
    ecg.plotValues(p5);

    // Update the information values of the ECG
    ecg.updateInfo(p5);
  }

  render() {
    return (
      <div className="flex">
        <div style={{ position: 'relative' }}>
          <Sketch setup={this.setup} draw={this.draw} />
          <span
            style={{
              position: 'absolute',
              top: '0.5em',
              left: '0.5em',
              fontSize: '1.15em',
              color: `rgb(${RGB_COLOR[0]}, ${RGB_COLOR[1]}, ${RGB_COLOR[2]})`,
            }}
          >
            II
          </span>
        </div>
        <div className="bpm flex" style={{ flex: 1, position: 'relative', marginLeft: '1em' }}>
          <span id="hr-bpm-value" style={{ fontSize: '6em', margin: 'auto' }}>
            60
          </span>{' '}
          <span style={{ position: 'absolute', top: '0.5em', left: '1em' }}>HR</span>
        </div>
      </div>
    );
  }
}

export default ECGSketch;
