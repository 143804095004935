import PropTypes from 'prop-types';
import React from 'react';

import './style/ExternalLink.scss';

const ExternalLink = ({ text, destination, ...props }) => (
  <a href={destination} target="_blank" rel="noreferrer" className="external-link" {...props}>
    {text}
  </a>
);

ExternalLink.propTypes = {
  text: PropTypes.string.isRequired,
  destination: PropTypes.string.isRequired,
};

export default ExternalLink;
