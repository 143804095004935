import React, { useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';

import BPSketch from './monitors/BPSketch.js';
import ECGSketch from './monitors/ECGSketch.js';
import PlethSketch from './monitors/PlethSketch.js';

import './style/Home.scss';

const Intro = () => (
  <div className="flex " style={{ marginBottom: '0.5em' }}>
    <Link
      to="/about"
      className="rounded header-link"
      style={{
        margin: 'auto auto 0 0',
        padding: '0.5em',
      }}
    >
      <div className="flex col" style={{ flex: 1 }}>
        <div>Anthony Bosshardt</div>
        <div>Medical Student • Software Engineer</div>
      </div>
    </Link>
    <div className="date text-right" style={{ marginTop: 'auto' }}>
      <span id="date-value" />
    </div>
  </div>
);

const GOL_INITIAL_LIFE_THRESHOLD = 0.85;
const GOL_REFRESH_TIME_MS = 250;
const GOL_CELL_RESOLUTION_PX = 10;

const Home = () => {
  const canvasRef = useRef(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');

    // Set canvas width and height
    canvas.width = window.innerWidth;
    canvas.height = window.innerHeight;

    // Calculate the number of columns and rows based on canvas size and resolution
    const cols = Math.floor(canvas.width / GOL_CELL_RESOLUTION_PX);
    const rows = Math.floor(canvas.height / GOL_CELL_RESOLUTION_PX);

    const createGrid = () => new Array(cols).fill(null).map(() => new Array(rows).fill(0));

    const randomizeGrid = (grid) =>
      grid.map((col) => col.map(() => +(Math.random() > GOL_INITIAL_LIFE_THRESHOLD)));

    let grid = createGrid();
    grid = randomizeGrid(grid);

    function drawGrid(grid) {
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      for (let col = 0; col < cols; col += 1) {
        for (let row = 0; row < rows; row += 1) {
          const cell = grid[col][row];
          ctx.beginPath();
          ctx.rect(
            col * GOL_CELL_RESOLUTION_PX,
            row * GOL_CELL_RESOLUTION_PX,
            GOL_CELL_RESOLUTION_PX,
            GOL_CELL_RESOLUTION_PX,
          );
          ctx.fillStyle = cell ? 'rgba(255,255,255,0.05)' : '#212121';
          ctx.fill();
        }
      }
      ctx.stroke();
    }

    function nextGeneration(grid) {
      const nextGrid = grid.map((arr) => [...arr]);
      for (let col = 0; col < grid.length; col += 1) {
        for (let row = 0; row < grid[col].length; row += 1) {
          const cell = grid[col][row];
          let numNeighbors = 0;
          for (let i = -1; i < 2; i += 1) {
            for (let j = -1; j < 2; j += 1) {
              if (i === 0 && j === 0) {
                continue;
              }
              const x = col + i;
              const y = row + j;
              if (x >= 0 && x < cols && y >= 0 && y < rows) {
                numNeighbors += grid[x][y];
              }
            }
          }

          if (cell === 1 && (numNeighbors < 2 || numNeighbors > 3)) {
            nextGrid[col][row] = 0;
          } else if (cell === 0 && numNeighbors === 3) {
            nextGrid[col][row] = 1;
          }
        }
      }
      return nextGrid;
    }

    function update() {
      grid = nextGeneration(grid);
      drawGrid(grid);
    }

    drawGrid(grid);
    const intervalId = setInterval(update, GOL_REFRESH_TIME_MS);

    return () => clearInterval(intervalId); // Cleanup interval on component unmount
  }, []);

  return (
    <div style={{ display: 'flex', height: '100vh' }}>
      <canvas
        ref={canvasRef}
        style={{
          position: 'fixed',
          top: 0,
          left: 0,
          height: '100vh',
          width: '100vw',
          zIndex: -1,
        }}
      />
      <div className="flex col vital-signs-container">
        <Intro />
        <ECGSketch />
        <PlethSketch />
        <BPSketch />
      </div>
    </div>
  );
};

export default Home;
